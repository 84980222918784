<template>
  <lidaPage
    :columns="tableOption"
    :query="inquiryPageSeller"
    :formColumns="formColumns"
    subTitle="总询价单数"
  >
    <template #documentState="scope">
      <div>{{ documentState[scope.row.state] }}</div>
    </template>
    <template #inquiryAmt="scope">
      <div>￥{{ scope.row.inquiryAmt }}</div>
    </template>
    <template #operation>
      <lida-table-column label="操作" width="50" fixed="right">
        <template #default="scope">
          <el-button @click="commands(scope.row, '查看')" type="text"
            >查看</el-button
          >
        </template>
      </lida-table-column>
    </template>
  </lidaPage>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  computed: {
    ...mapState("purchaseMudule", ["documentState"]),
  },
  data() {
    return {
      tableData: [], //列表数据
      menusData: [
        {
          name: "查看",
        },
        {
          name: "删除",
          show: "true",
        },
      ],
      tableOption: [
        {
          label: "询价单号",
          prop: "inquiryNo",
        },
        {
          label: "单据状态",
          prop: "documentState",
        },
        {
          label: "询价企业",
          prop: "buyerEnterpriseName",
        },
        {
          label: "询价客户",
          prop: "buyerName",
        },
        {
          label: "联系方式",
          prop: "buyerPhone",
        },
        {
          label: "询价品牌",
          prop: "inquiryBrands",
        },
        {
          label: "询价金额",
          prop: "inquiryAmt",
        },
        {
          label: "询价数量",
          prop: "inquiryQty",
        },
        {
          label: "项目名称",
          prop: "projectName",
        },
        {
          label: "报价有效期限",
          prop: "documentTime",
        },
        {
          label: "询价备注",
          prop: "remarks",
        },
      ],
      formColumns: [
        {
          type: "date",
          prop: "time",
          label: "询价日期",
        },
        {
          type: "input",
          prop: "wd",
          label: "综合搜索",
        },
        {
          type: "select",
          prop: "documentStatus",
          label: "单据状态",
          options: [
            {
              value: "WAIT_QUOTE",
              label: "待报价",
            },
            {
              value: "COMPLETE_QUOTE",
              label: "已报价",
            },
            {
              value: "REVOKE",
              label: "已撤销",
            },
          ],
        },
      ],
    };
  },
  methods: {
    ...mapActions("mysaleMudule", ["inquiryPageSeller"]),
    commands(row, type) {
      switch (type) {
        case "删除":
          this.removeInquiryReq().then();
          break;
        case "撤销":
          this.revokeInquiryReq().then();
          break;
        case "查看":
          this.$router.push({
            path: "/mysale/enquiryDetails",
            query: {
              id: row.id,
              type: "customer",
            },
          });
          break;
        case "查看报价":
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.highlight {
  color: @dh-color;
}
</style>
